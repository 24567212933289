import { 
  Button, 
  Col, 
  Row, 
  message, 
  Modal, 
  Input,
  Radio, 
  Spin,
  InputNumber
} from "antd";
import "./index.scss";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import React, { useEffect, useState, useRef } from "react";
import Footer from "../../reusables/Footer";
import Header from "../../reusables/Header";
import {
  CartItem1,
  JayAutos,
  LogoBlack
} from "../../assets/icons";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  removeFromCart,
  clearCart,
  AddInstoreOrders,
  AddInstoreUSSDOrders,
} from "../../redux/actions/orders";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ADD_ORDERS,
  GET_INSTORE_ORDERS,
  USE_ORGANISATION_GIFTCARD,
  GET_USERS,
  UPDATE_USER,
  CREATE_DELIVERY
} from "../../constants";
import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";
import {
  clearUSSDState,
  getMerchatLoyalty,
  getUrewardsAuth,
  giftCardAction,
  USSDOrderSuccess,
} from "../../redux/actions/ussdActions";
import USSDModal from "./modal/USSDModal";
import ConfirmModal from "./modal/ConfirmModal";
import Pusher from "pusher-js";
import SuccessModal from "./modal/successModal";
import addNotification from "react-push-notification";
import OrderSuccess from "./modal/ordersuccess";
import AskPayment from "./modal/askPayemnt";
import { getApp } from "../../redux/actions/merchant";
import { createDeliveryFez, fetchDeliveryPrice, getAddressFromLongAndLat, getFezDeliveryPrice, getLocation, recordSales } from "../../redux/actions/delivery";
import moment from "moment";
import { sendDelivery } from "../../redux/actions/delivery";
import GoogleMapComponent from "./modal/GoogleLocation";
import { LoadScript, Autocomplete } from '@react-google-maps/api';
import { LoadingOutlined } from "@ant-design/icons";
import { formatGoogleAddress } from "../../utilities/formatAddressFromApi";

const statesInNigeria = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
  "Federal Capital Territory (FCT)"
];

function Cart() {
  const { TextArea } = Input;
  const location = useLocation();
  const dispatch = useDispatch();
  const storedMerchant = localStorage.getItem("merchant_details");
  const merchant = storedMerchant ? JSON.parse(storedMerchant) : "";
  const storedCustomerPoint = localStorage.getItem("total_customer_point");
  const totalCustomerPoints = JSON.parse(storedCustomerPoint)
    ? JSON.parse(storedCustomerPoint)
    : 0;
  const storageUser = localStorage.getItem("user_customer_details");
  const { merchantDetails } = useSelector((state) => state.merchant);
  const { orderdetails } = useSelector((state) => state.Orders);
  const user = storageUser ? JSON.parse(storageUser) : null;
  const { carts } = useSelector((state) => state.Order);
  const {
    uRewardsAuth,
    status,
    ussdOrderSuccess,
    merchantloyaltySuccess,
    giftcard,
  } = useSelector((state) => state.urewardsAuth);
  const {
    merchanted,
  } = useSelector((state) => state.merchantState);
  const {
    isbranchSet,
    branch_address1,
    branch_address2,
    branch_id
  } = useSelector((state) => state.branch);

  const [addOrders, { error }] = useMutation(ADD_ORDERS);
  const [ createDelivery, { error: errorDelivery} ] = useMutation(CREATE_DELIVERY);

  const isHomeBranch = localStorage.getItem("homebranch");
  const isBranch = localStorage.getItem("branchdetails");
  let homebranch;
  let branchh;
  if(isHomeBranch){
    homebranch = JSON.parse(isHomeBranch)
  }
  if(isBranch){
    branchh = isBranch ? JSON.parse(isBranch) : {branch_id: branch_id}
  }

  // console.log(branchh.branch_id, homebranch[0].id);
  const {
    loading: getInStoreLoading,
    data: inStoreData,
    refetch,
  } = useQuery(GET_INSTORE_ORDERS, {
    variables: 
    { merchant_id: merchantDetails?.id || merchant?.id, 
      branch_id, 
      homebranch_id: branchh && branchh.branch_id === homebranch[0].id ? homebranch[0].id : null
    },
  });

  // const [showLoginModal, setShowLoginModal] = useState(false);
  const [ addressByLocation, setAddressByLocation ] = useState(false);
  const [merchantUrl, setMerchantUrl] = useState(null);
  const [askCart, setAskCart] = useState(false);
  const [confirmTill, setConfirmTill] = useState(false);
  const [showInStoreOrders, setShowInStoreOrders] = useState(false);
  const [confirmClear, setConfirmClear] = useState(false);
  const [confirmUSSD, setConfirmUSSD] = useState(false);
  const [openBankModal, setOpenBankModal] = useState(false);
  const [successUSSD, setSuccessUSSD] = useState(false);
  const [confirmGiftCard, setConfirmGiftCard] = useState(false);
  const [ email, setEmail ] = useState("");
  const [ address_line1, setAddress_line1 ] = useState("");
  const [ address_line2, setAddress_line2 ] = useState("");
  const [ state, setState ] = useState(null);
  const [ country, setCountry ] = useState("");
  const [ shouldUpdateDetails, setShouldUpdateDetails ] = useState(false);
  const [ paywithDelivery, setPaywithDelivery ] = useState(true);
  const [ deliveryMethod, setDeliveryMethod ] = useState("Delivery");
  const [ postal_code, setPostal_code ] = useState("");
  const [ orderNotes, setOrderNotes ] = useState("");
  const [ isBeepBeep, setIsBeepBeep ] = useState(false);
  const [isStripeEnabled, setIsStripeEnabled] = useState(false);
  const [ isCredPalEnabled, setIsCredPalEnabled ] = useState(false);
  const [ isPayStack, setIsPayStack ] = useState(false);
  const [ stripePaymentSucess, setStripePaymentSuccess ] = useState(false);
  const [ selectedBranchDetails, setSelectedBranchDetails ] = useState(null);
  const [ deliveryPrice, setDeliveryPrice ] = useState(null); 
  const [ loading, setLoading ] = useState(false);
  const [applications, setApplications] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const inputRef = useRef(null);
  const [ serviceAmount, setServiceAmount ] = useState(0);
  const [ deliveryDetails, setDeliveryDetails ] = useState({
    email: "",
    deliveryAddress: "",
    orderNotes
  });
  const [ customerLongAndLat, setCustomerLongAndLat ] = useState({
    longitude: null,
    latitude: null
  });
  const [ openSelectGooglelocation, setOpenSelectGoogleLocation ] = useState(false);
  const [ pickupLongAndLat, setPickupLongAndLat ] = useState({
    longitude: null,
    latitude: null
  });
  const [ weight, setWeight ] = useState(null);
  const [ showWeightInput, setShowWeightInput ] = useState(false);
  const { 
    refetch: refetchGet
 } = useQuery(
        GET_USERS, 
        { 
            variables: { id: user && user.user_id ? user.user_id.toString() : null },
            onCompleted: (data) => {
                const checkUser = data.getUser.user;
                const { longitude, latitude } = checkUser;
                checkUser.email && setEmail(checkUser.email);
                checkUser.address_line1 && setAddress_line1(checkUser.address_line1);
                checkUser.address_line2 && setAddress_line2(checkUser.address_line2);
                checkUser.state && setState(checkUser.state);
                checkUser.country && setCountry(checkUser.country);
                checkUser.postal_code && setPostal_code(checkUser.postal_code);
                longitude && latitude && setCustomerLongAndLat({
                  longitude: checkUser.longitude,
                  latitude: checkUser.latitude
                });
                // merchant.currency === "NGN" &&
                console.log("how many times");
                
                longitude && latitude && location.search === "" &&  getMerchantLatLong(checkUser.latitude, checkUser.longitude);
                checkUser.postal_code && setPostal_code(checkUser.postal_code);
                const userd = JSON.parse(localStorage.getItem("user_customer_details"));
                localStorage.setItem("user_customer_details", JSON.stringify({...userd, id: checkUser.id}))
                if(
                  checkUser.email || 
                  checkUser.address_line1 || 
                  checkUser.address_line2 || 
                  checkUser.state || 
                  checkUser.country || 
                  checkUser.postal_code 
                ){
                  setShouldUpdateDetails(true);
                }else{
                  setDeliveryDetails({
                    email: checkUser.email,
                    deliveryAddress: `${checkUser.address_line1}, ${checkUser.address_line2}, ${checkUser.state}, ${checkUser.country}`
                  });
                  setShouldUpdateDetails(false);
                }
            }
        },
    );

  const [updateUser] = useLazyQuery(UPDATE_USER);

  useEffect(() => {
    const gain = totalOrder(carts) > 10000 ? 1000 : 500;
    const amt = 100 + (1.5/100 * Number(totalOrder(carts))) + gain;
    setServiceAmount(Math.round(amt));
  }, [carts]);

  useEffect(() => {
    const doesAllHaveWeight = carts.some(cart => !cart.weight);
    // if(doesAllHaveWeight) handleShowWeight();
    if(!doesAllHaveWeight) calculateWeight(carts);
  }, [carts]);

  const handleShowWeight = () => {
    if(deliveryMethod === "Delivery") {
      let inputWeight;
      Modal.info({
        title: "Item(s) selected do not have specified weight, kindly input estimated weight (in kg)!",
        content: (
          <InputNumber style={{width: "100%"}} onChange={(value)=> inputWeight = value } />
        ),
        onOk: () => {
          if(!inputWeight) return message.warn("kindly input weight value!");
          setWeight(inputWeight);
        },
        onCancel: () => {
          console.log("no weight selected!");
        },
        closable: true
      })
    }
   
  }

  useEffect(() => {
    if(weight && state) getDeliveryPrice()
  }, [weight, state])

  const handleSetDelivery = (method) => {
    setDeliveryMethod(method);
    method === "Delivery" ? setPaywithDelivery(true) : setPaywithDelivery(false);

  }

  const calculateComission = (price) => {
    const merchant = JSON.parse(localStorage.getItem("merchant_details"))
    const commission = Number(merchant.commission) || 1;

    const finalPrice = ((commission / 100) * Number(price)) + Number(price) + 500;


    return finalPrice.toFixed(0);
  }

  const calculateWeight = (products) => {
    const totalWeight = products.reduce((prev, curr) => {
      return prev + Number(curr.weight)
    }, 0);
    setWeight(totalWeight);
  }

  const getDeliveryPrice = () => {
    const merchant = JSON.parse(localStorage.getItem("merchant_details"))
    const savedBranch = JSON.parse(localStorage.getItem("branchdetails"));
    const isHomeBranch = savedBranch?.branch_name && savedBranch?.branch_name?.toLowerCase().includes("home") || ""
    const isLagos = savedBranch.address_line2 && savedBranch.address_line2.includes("lagos") ? savedBranch.address_line1 + " " +savedBranch.address_line2 + " Nigeria" : savedBranch.address_line1 + " " +savedBranch.address_line2 

    setLoading(true);
    let payload;

    if(isHomeBranch && merchant.state){
      payload = {
        state: state && state.toLowerCase().includes("abuja") || state && state.toLowerCase().includes("federal") ? "FCT": state,
        pickUpState: statesInNigeria.includes(merchant.state) ? merchant.state : "Lagos",
        weight,
      }
    }else if(!isHomeBranch && savedBranch?.state ){
      payload = {
        state: state && state.toLowerCase().includes("abuja") || state && state.toLowerCase().includes("federal") ? "FCT": state,
        pickUpState: statesInNigeria.includes(savedBranch.state) ? savedBranch.state : "Lagos",
        weight,
      }
    }else {
      payload = {
        state: state && state.toLowerCase().includes("abuja") || state && state.toLowerCase().includes("federal") ? "FCT": state,
        pickUpState: statesInNigeria.includes(merchant.state) ? merchant.state : "Lagos",
        weight,
      }
    }

    getFezDeliveryPrice(payload)
    .then(res => {
      if(res.status === 200) {
        setLoading(false);
        setDeliveryPrice(res.data.Cost.cost);
      }
    })
    .catch(err => {
      console.log("err", err?.response);
      
      setLoading(false);
      message.info("Unable to process delivery at this time, delivery would be processed offline.");
    })
  }

  const getMerchantLatLong = (lat, long) => {
    const merchant = JSON.parse(localStorage.getItem("merchant_details"))
    const savedBranch = JSON.parse(localStorage.getItem("branchdetails"));
    const isHomeBranch = savedBranch?.branch_name && savedBranch?.branch_name?.toLowerCase().includes("home") || ""
    const isLagos = savedBranch.address_line2 && savedBranch.address_line2.includes("lagos") ? savedBranch.address_line1 + " " +savedBranch.address_line2 + " Nigeria" : savedBranch.address_line1 + " " +savedBranch.address_line2 
    const merchantAddress = savedBranch.address_line2 ? isLagos : merchant.state;

    // console.log("fjf", savedBranch);
    setLoading(true);
    let deliveryPrice;
    if(isHomeBranch && merchant.longitude && merchant.latitude){
      setPickupLongAndLat({
        longitude: merchant.longitude,
        latitude: merchant.latitude
      })
      const payload = {
        latitude: lat,
        longitude: long,
        pickupLatitude: merchant.latitude,
        pickupLongitude: merchant.longitude
      }
       fetchDeliveryPrice(payload)
        .then(res => {
          if(res.status === 200 || res.status === 201){
            setLoading(false);
            setDeliveryPrice(Number(calculateComission(res.data.data.amount)));
            setIsPayStack(true);
            deliveryPrice = Number(calculateComission(res.data.data.amount));
            !getInStoreLoading &&
          handleAreYouSure(carts, user, totalOrder(carts)+Number(calculateComission(res.data.data.amount)));
          }
        })
        .catch(err => {
          setLoading(false);
          if(window.location.origin.includes("thecualashop.com") && merchant.currency === "NGN"){
            deliveryPrice = Number(process.env.REACT_APP_DELIVERY_NIG_PRICE);
            setDeliveryPrice(Number(process.env.REACT_APP_DELIVERY_NIG_PRICE));
          }else if(window.location.origin.includes("thecualashop.com") && merchant.currency === "GBP"){
            deliveryPrice = Number(process.env.REACT_APP_DELIVERY_UK_PRICE);
            setDeliveryPrice(Number(process.env.REACT_APP_DELIVERY_UK_PRICE))
          }else {
            // handleSetDelivery("Pickup");
            // message.error("Unable to process delivery at this time");
          }
          console.log("er", err.response);
        })
    }else if(!isHomeBranch && savedBranch?.longitude && savedBranch?.latitude){
      setPickupLongAndLat({
        longitude: savedBranch?.longitude,
        latitude: savedBranch?.latitude
      })
      const payload = {
        latitude: lat,
        longitude: long,
        pickupLatitude: savedBranch?.latitude,
        pickupLongitude: savedBranch?.longitude
      }

      fetchDeliveryPrice(payload)
        .then(res => {
          if(res.status === 200 || res.status === 201){
            setLoading(false);
            setDeliveryPrice(Number(calculateComission(res.data.data.amount)));
            setIsPayStack(true);
            deliveryPrice = Number(calculateComission(res.data.data.amount));
            !getInStoreLoading &&
          handleAreYouSure(carts, user, totalOrder(carts)+Number(calculateComission(res.data.data.amount)));
          }
        })
        .catch(err => {
          setLoading(false);
          if( window.location.origin.includes("thecualashop.com") && merchant.currency === "NGN"){
            deliveryPrice = Number(process.env.REACT_APP_DELIVERY_NIG_PRICE);
            setDeliveryPrice(Number(process.env.REACT_APP_DELIVERY_NIG_PRICE));
          }else if(window.location.origin.includes("thecualashop.com") && merchant.currency === "GBP"){
            deliveryPrice = Number(process.env.REACT_APP_DELIVERY_UK_PRICE);
            setDeliveryPrice(Number(process.env.REACT_APP_DELIVERY_UK_PRICE))
          }else {
            // handleSetDelivery("Pickup");
            // message.error("Unable to process delivery at this time");
          }
          console.log("er", err.response);
        })
    }else{
      getLocation(merchantAddress)
      .then(res => {
        if(res.status === 200 || res.status === 201){
          if(res.data.length === 0) return Modal.error({
            title: "Unable to determine merchant's location",
            onOk: () => {
              setDeliveryMethod("Pickup");
              setPaywithDelivery(false);
              setLoading(false);
            }
          })
          // return console.log("res.data", res.data);
          setPickupLongAndLat({
            longitude: res?.data.results[0]?.geometry?.location?.lng,
            latitude: res?.data?.results[0]?.geometry?.location?.lat
          })
          const payload = {
            latitude: lat,
            longitude: long,
            pickupLatitude: res?.data?.results[0]?.geometry?.location?.lat || null,
            pickupLongitude: res?.data.results[0]?.geometry?.location?.lng || null
          }
           fetchDeliveryPrice(payload)
            .then(res => {
              if(res.status === 200 || res.status === 201){
                setLoading(false);
                setDeliveryPrice(Number(calculateComission(res.data.data.amount)));
                deliveryPrice = Number(calculateComission(res.data.data.amount));
                setIsPayStack(true);
                !getInStoreLoading &&
              handleAreYouSure(carts, user, totalOrder(carts)+Number(calculateComission(res.data.data.amount)));
              }
            })
            .catch(err => {
              setLoading(false);
              if(window.location.origin.includes("thecualashop.com") && merchant.currency === "NGN"){
                deliveryPrice = Number(process.env.REACT_APP_DELIVERY_NIG_PRICE);
                setDeliveryPrice(Number(process.env.REACT_APP_DELIVERY_NIG_PRICE));
              }else if(window.location.origin.includes("thecualashop.com") && merchant.currency === "GBP"){
                deliveryPrice = Number(process.env.REACT_APP_DELIVERY_UK_PRICE);
                setDeliveryPrice(Number(process.env.REACT_APP_DELIVERY_UK_PRICE))
              }else {
                // handleSetDelivery("Pickup");
                // message.error("Unable to process delivery at this time");
              }
              // message.error("Unable to process delivery at this time");
            })
        }
      })
      .catch(err => {
        setLoading(false);
        const error = err?.response?.data?.message || "unable to determine merchant location";
        message.error(error);
      })
    }
    return deliveryPrice;
  }

  const sendDeliveryRequest = () => {
    const { customer, total, created_at, order_id } = orderdetails;
    // console.log("er", orderdetails);
    const user = JSON.parse(localStorage.getItem("user_customer_details"));

    const branch = JSON.parse(localStorage.getItem("branchdetails"));
    const merchantAddress = branch.address_line2 ? branch.address_line2 : merchantDetails.state;
    const recipents = {
      name: `${customer.first_name} ${customer.last_name ? customer.last_name : ""}`,
      mobile: customer.phone_number,
      email: customer.email,
      instruction: deliveryDetails.orderNotes ? deliveryDetails.orderNotes : "New Order"
    }

    const destinationInformation = {
      address: `${address_line1}${address_line2 ? ", " +address_line2 : ""}, ${state}, ${country}`,
      locLatitude: customerLongAndLat.latitude, 
      locLongitude: customerLongAndLat.longitude
    }

    setDeliveryDetails({
      deliveryAddress: destinationInformation.address
    })

    const data = [{
      recipientAddress: destinationInformation.address,
      recipientState: state,
      recipientName: `${customer.first_name} ${customer.last_name ? customer.last_name : ""}`,
      recipientPhone: customer.phone_number,
      itemDescription: deliveryDetails.orderNotes,
      valueOfItem: total,
      weight,
      uniqueID: order_id,
      BatchID: order_id
    }]

    // const data = {
    //   fleetTypeId: process.env.REACT_APP_FLEET_ID_BIKES,
    //   fleetType: "Bikes",
    //   recipient: recipents,
    //   destinationInformation: destinationInformation,
    //   deliveryDate: moment(created_at).format("YYYY-MM-DD"),
    //   deliveryTime: moment(created_at).format("h:mm A"),
    //   instantDelivery: true,
    //   recipientPays: true,
    //   currency: merchantDetails.currency,
    //   customerToPay: {
    //       orderCost: Number(total),
    //       deliveryCost: deliveryPrice
    //   },
    //   deliveryNote: "New Order.",
    //   pickupInformation: {
    //     name: `${customer.first_name} ${customer.last_name ? customer.last_name : ""}`,
    //     mobile: customer.phone_number,
    //     email: customer.email,
    //     instructions: "New Order."
    //   },
    //   pickup: {
    //     address: merchantAddress,
    //     locLatitude: parseFloat(pickupLongAndLat.latitude),
    //     locLongitude: parseFloat(pickupLongAndLat.longitude)
    //   },
    //   twoWays: true,
    //   marketplace: false,
    // };

    // console.log("dta", data);
    createDeliveryFez(data)
    .then(res => {
      if(res.status === 200 || res.status === 201){
        const order = res.data.orderNos;
        const orderId = Object.keys(order);
        const orderRequestID = Object.values(order);
        
        const payload = {
          recipient: JSON.stringify(recipents),
          status: "pending",
          delivery_time: String(moment(created_at).format("h:mm A")),
          delivery_date: String(moment(created_at).format("DD-MM-YYYY")),
          delivery_amount: deliveryPrice,
          order_amount: Number(total),
          destinationInformation: JSON.stringify(destinationInformation),
          merchant_id: merchantDetails.id,
          user_id: Number(user.user_id),
          order_id: orderdetails.order_id,
          orderID: `${orderdetails.orderID}-${orderId[0]}`,
          branch_id: branch.branch_id,
          delivery_id: orderRequestID[0]
        }
        createDelivery({ variables: { data: payload }});
      }
    })
    .catch(err => {
      console.log("err", err);
      message.info("Unable to process delivery at this time, delivery would be processed offline.");
    })
  }

  const getUserLocation = () => {
    if(!address_line1 || !address_line2) return message.info("complete your address details")
    const address = `${address_line1} ${address_line2} ${state}`;
    setLoading(true);
    getLocation(address)
    .then(res => {
      if(res.status === 200){
        setLoading(false);
        if(res.data.length > 0){
          const latitude = res.data[0].lat;
          const longitude = res.data[0].lon;
          
          // console.log("r", res.data);
          setCustomerLongAndLat({
            longitude: longitude,
            latitude: latitude
          });
          // merchant.currency === "NGN" &&
          location.search === "" && getMerchantLatLong(latitude, longitude);
        }else{
          return Modal.info({
            title: "Allow to use current location",
            onOk: () => {
              // setDeliveryMethod("Pickup");
              // setPaywithDelivery(false);
              // setLoading(false);
              if (navigator.geolocation) {
                // get the current users location
                navigator.geolocation.getCurrentPosition(
                  (position) => {
                    const { latitude, longitude } = position.coords;
                    
                    setCustomerLongAndLat({
                      longitude: longitude,
                      latitude: latitude
                    })
                    // merchant.currency === "NGN" &&
                    getMerchantLatLong(latitude, longitude);
                  },
                  (error) => {
                    setLoading(false)
                    message.warn("Location is needed to process delivery");
                    console.error('Error getting user location:', error);
                  }
                );
              }
            },
            okCancel: "No",
            cancelText: "No",
            okText: "Yes",
            onCancel: () => {
              setAddress_line1("");
              setAddress_line2("")
              message.warn("Location is needed to process delivery");
              setLoading(false);
            }
          })
        }
      }
    })
    .catch(err => {
      console.log("err", err);
      Modal.error({
        title: "Unable to determine your current location",
        onOk: () => {
          setDeliveryMethod("Pickup");
          setPaywithDelivery(false);
          setLoading(false);
        }
      })
    })
  }

  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };
  // phone_number

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const location = place.geometry.location;
      const formatedAdress = formatGoogleAddress(place.address_components);

      setAddress_line1(formatedAdress.addressLine1);
      setAddress_line2(formatedAdress.addressLine2);
      setPostal_code(formatedAdress.postalCode);
      setState(formatedAdress.state);
      setCountry(formatedAdress.country);
      setCustomerLongAndLat({
        longitude: location.lng(),
        latitude: location.lat()
      })
      // merchant.currency === "NGN" && 
      location.search === "" && getMerchantLatLong(location.lat(), location.lng())
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };

  // const handleSetLocationCurrent = () => {
  //   return setOpenSelectGoogleLocation(true)
  // }

  const getLocationCoordinate = () => {
    if (navigator.geolocation) {
      // get the current users location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          
          setCustomerLongAndLat({
            longitude: longitude,
            latitude: latitude
          })

          getAddressFromLongAndLat(longitude, latitude)
          .then(res => {
            if(res.status === 200){
              const {  country, county, postcode, state } = res.data.address;

              setCountry(country);
              setState(state)
              setPostal_code(postcode)
              setAddress_line2(county)
              res.data.address.amenity ? setAddress_line1(res.data.address.amenity) : setAddress_line1(null);
            
              // merchant.currency === "NGN" && 
              getMerchantLatLong(latitude, longitude);
            }
          })
          .catch(err => {
            setLoading(false)
            message.warn("Location is needed to process delivery");
            console.error('Error getting user location:', error);
          })
        },
        (error) => {
          setLoading(false)
          message.warn("Location is needed to process delivery");
          console.error('Error getting user location:', error);
        }
      );
    }
  }

  const handleUpdateDetails = () => {
    updateUser({ 
      variables: { 
          data: {
            id: user && user.id ? user.id : null,
            email,
            address_line1,
            address_line2,
            state,
            country,
            postal_code,
            latitude: customerLongAndLat.latitude.toString(),
            longitude: customerLongAndLat.longitude.toString(),
            merchant_id: merchant.id.toString()
          } 
      }, 
      onCompleted: (data) => {
          // console.log(data);
          // refetchGet();
      } 
  })
  }
  const [
    spendGiftCard,
    {
      data: spendData,
      loading: spendLoading,
      refetch: spendRefetch,
    },
  ] = useLazyQuery(USE_ORGANISATION_GIFTCARD);

  // const []
  const toastConfig = {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };
  const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v2`;
  const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY;
  const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER;
  const authUrl = `${BASE_URL}/pusher/auth`;

  useEffect(() => {
    if (merchanted?.payout_active) {
      if (status !== "success") {
        const email = merchanted?.email || merchant?.email;
        const location = window?.location?.pathname ?? '/404';
        // dispatch(getUrewardsAuth({ email, location }));
      }
    }

    return () => {}
    // if(status)
  }, [status]);

  useEffect(()=>{
    dispatch( getApp() )
    .then((res)=>{
      const application = res.data.data;
      setApplications(application)

      const beep = application.filter((app)=>{
        return app.app.app_name.toLowerCase().includes("beepbeep")
      });

      const isPayStackApp = application.filter((app) => {
        return app.app.app_name === "Paystack Terminal";
      });

      const isStripe = application.filter((app) => {
        return app.app.app_name === "Stripe";
      });

      const isCredPalActive = application.filter((app)=>{
        return app.app.app_name === "Credpal Loystar Credit Service";
      });

      beep.length > 0 
        ? setIsBeepBeep(true)
          : setIsBeepBeep(false)

      isStripe.length > 0 
        ? setIsStripeEnabled(true) 
          : setIsStripeEnabled(false);
  
      isPayStackApp.length > 0 
        ? setIsPayStack(true) 
          : deliveryMethod === "Delivery"
           ? setIsPayStack(true) 
            : setIsPayStack(false);

      isCredPalActive.length > 0 
        ? setIsCredPalEnabled(true) 
          : setIsCredPalEnabled(false);

    })
    .catch((err)=>{
      console.log("err", err);
    });
   
    return () => {}
  }, []);

  useEffect(() => {
    const delivery = JSON.parse(localStorage.getItem("delivery_price"));
    setDeliveryPrice(delivery);
    !getInStoreLoading &&
            handleAreYouSureForStrip(carts, user, totalOrder(carts));
    !getInStoreLoading && payAfterStrip();
  }, [location, getInStoreLoading]);

  useEffect(() => {
    const giftCard = localStorage.getItem("gift_card");
    if (giftCard) {
      dispatch(giftCardAction(JSON.parse(giftCard)));
    }
    return () => {}
  }, [dispatch]);

  useEffect(() => {
    if (status === "success") {
      const pusher = new Pusher(PUSHER_KEY, {
        cluster: PUSHER_CLUSTER,
        authEndpoint: authUrl,
        auth: {
          params: {
            uid: uRewardsAuth?.uid,
            access_token: uRewardsAuth?.access_token,
            client: uRewardsAuth?.client,
          },
        },
      });

      const channel = pusher.subscribe(
        `private-ussd_payment_${merchanted?.id || merchantDetails?.id}`
      );

      channel.bind("new_payment_made", function (data) {
        const {
          status,
          ref_code,
          invoice: { status: invoiceStatus, paid_at, invoice_id, amount },
          customer: { first_name, last_name, phone_number, email },
        } = data;

        if (status === "success") {
          orderdetails && toast.success(
            `Successfully paid ${
              merchantDetails?.currency + totalOrder(carts).toFixed(2)
            } via USSD!`,
            toastConfig
          );

          !orderdetails && toast.success(
            `Successfully paid for booking via USSD!`,
            toastConfig
          );

          orderdetails && addNotification({
            title: "Payment Sucessful!",
            message: `Payment for Order ${orderdetails?.order_id} is Successful`,
            theme: "darkblue",
            backgroundTop: "green",
            native: true,
            duration: 300000,
            onClick: () => (window.location = "/cart"),
          });
          // message.success("Payment Sucessful!")
          setOpenBankModal(false);
          setAskCart(false);
          orderdetails && dispatch(USSDOrderSuccess());

          // toast.success(`Order has been made`, toastConfig);
        }
      });

      return () => {
        pusher.unsubscribe(
          `private-ussd_payment_${merchant?.id || merchantDetails?.id}`
        );
      };
    }

    return () => {}
  }, []);

  useEffect(() => {
    if (merchanted?.payout_active) {
      if (merchantloyaltySuccess) return;
      if (status === "success") {
        const { client, access_token, uid, email } = uRewardsAuth;
        const headers = {
          client: uRewardsAuth.client,
          "access-token": uRewardsAuth.access_token,
          uid: uRewardsAuth.uid,
        };
        dispatch(getMerchatLoyalty(headers));
      }
    }
    return () => {}
  }, [dispatch, status, merchanted, merchantloyaltySuccess, uRewardsAuth]);

  useEffect(() => {
    if (ussdOrderSuccess) {
      addOrders({ 
        variables: { data: orderdetails },
        onCompleted: () => {
          handlePostSales(orderdetails, "m_transfer");
        } 
      });
      paywithDelivery && merchantDetails.currency === "NGN" && sendDeliveryRequest();
    }
    return () => {}
  }, [ussdOrderSuccess, orderdetails, addOrders]);

  const addQuantity = (product, qty, totalProductCount) => {
    let quantity;
    if(product.custom_quantities && product.custom_quantities.length > 0){
      quantity = qty + Number(product.custom_quantities[0].quantity)
    }else{
      quantity = qty + 1;
    }
    if (product.track_inventory) {
      if (totalProductCount !== null) {
        if (quantity > Number(totalProductCount)) {
          toast.warning(`${product.name} is out of stock`, toastConfig);
          return;
        }
      }
    }
    dispatch(addToCart({ product, quantity, totalProductCount }));
    // message.success("hey")
  };

  const reduceQuantity = (product, qty, totalProductCount) => {
    if (qty <= 1) {
      return handleDeleteOder(product);
    } else {
      let quantity;
      if(product.custom_quantities && product.custom_quantities.length > 0){
        if (qty <= Number(product.custom_quantities[0].quantity)) {
          return handleDeleteOder(product);
        }
        quantity = qty - Number(product.custom_quantities[0].quantity)
      }else{
        quantity = qty - 1;
      }
      dispatch(addToCart({ product, quantity, totalProductCount }));
    }
  };

  const totalOrder = (cart) => {
    if (cart && cart.length === 0 ) return 0;
    const cartItemWithVariants = cart.filter((item)=>{
      return item.variants && item.variants.length > 0;
    });

    const cartItemsWithCust = cart.filter((item)=>{
      return item.custom_quantities && item.custom_quantities.length > 0;
    });

    const cartBundle = cart.filter((item)=>{
      return item.bundle_quantity && typeof item.bundle_quantity === "object";
    });

    const normItem = cart.filter((item)=>{
      return item.variants && item.variants.length === 0 && item.custom_quantities && item.custom_quantities.length === 0;
    });
    
    const totalVaraiant = cartItemWithVariants.reduce((prev, curr)=>{
      return Number(prev) + (Number(curr.quantity) * Number(curr.variants[0].price));
    }, 0);

    const totalCust = cartItemsWithCust.reduce((prev, curr)=>{
      return Number(prev) + (Number(curr.quantity) * Number(curr.custom_quantities[0].price));
    }, 0);

    const totalBundle = cartBundle.reduce((prev, curr)=>{
      return Number(prev) + (Number(curr.quantity) * Number(curr.price));
    }, 0);

    const otherTotal = normItem.reduce((prev, curr) => {
      return prev + Number(curr.quantity) * Number(curr.price);
    }, 0); 
    if(deliveryPrice) return otherTotal + totalCust + totalVaraiant + totalBundle + Number(deliveryPrice);
    return otherTotal + totalCust + totalVaraiant + totalBundle;
  };

  const handleDeleteOder = (cart) => {
    dispatch(removeFromCart(cart));

    toast.success(`${cart ? cart.name : "Item"} Remove from Cart`, toastConfig);
  };

  // const handleCheckout = (cart) => {
  //   if (user) {
  //     setShowLoginModal(true);
  //   }
  //   const { user_id, first_name, last_name, phone_number, email, sex } = user;

  //   history.push("/check-out");
  // };

  const handleAreYouSure = (carts, user, total) => {
    // refetch();

    if (carts.length === 0) return;

    const orderID =
      !getInStoreLoading && Number(inStoreData?.getInstoreOrders?.length) + 1;
    const newTotal = deliveryPrice ? deliveryPrice + total : total;
    // console.log("gh", newTotal);

    const deliveryAddress = `${address_line1}${address_line2 ? ", " +address_line2 : ""}, ${state}, ${country}`;
    const deliveryDetails = {
      deliveryAddress,
      orderNotes,
      email
    }
    !getInStoreLoading &&
      dispatch(AddInstoreOrders({ carts, user, total:newTotal, orderID, deliveryDetails }));

    // setAskCart(true);
  };

  const handleAreYouSureForStrip = (carts, user, total) => {
    // refetch();

    if (carts.length === 0) return;

    const orderID =
      !getInStoreLoading && Number(inStoreData?.getInstoreOrders?.length) + 1;

      const deliveryAddress = `${address_line1}${address_line2 ? ", " +address_line2 : ""}, ${state}, ${country}`;
      const deliveryDetails = {
        deliveryAddress,
        orderNotes,
        email
      }

    !getInStoreLoading &&
      dispatch(AddInstoreUSSDOrders({ carts, user, total, orderID, deliveryDetails }));

    // setAskCart(true);
  };

  const handlePostSales = (data, card) => { 
    
    recordSales(merchant.email, data, card)
    .then(res => {
      if(res.status === 200){
        console.log("Sale has been posted!");
      }
    })
    .catch(err => {
      console.log("Unable to post sales", err.response);
    })
  }

  useEffect(() => {
    refetch();

    return () => {}
  }, [refetch]);

  // useEffect(() => {
  //   const body = document.querySelector("body");
  //   body.style.background = "#000";
  // }, []);

  const handleUSSDPayment = (carts, user, total) => {
    if (carts.length === 0) return;
    const deliveryAddress = `${address_line1}${address_line2 ? ", " +address_line2 : ""}, ${state}, ${country}`;
      const deliveryDetails = {
        deliveryAddress,
        orderNotes,
        email
      }

    const orderID =
      !getInStoreLoading && Number(inStoreData?.getInstoreOrders?.length) + 1;

    !getInStoreLoading &&
      dispatch(AddInstoreUSSDOrders({ carts, user, total, orderID, deliveryDetails }));

    // const email = merchant?.email || merchantDetails?.email;
    // dispatch( getUrewardsAuth({email}) );

    setOpenBankModal(true);
  };

  const handlePayStackPayment = (carts, user, total) => {
    if (carts.length === 0) return;
    const deliveryAddress = `${address_line1}${address_line2 ? ", " +address_line2 : ""}, ${state}, ${country}`;
      const deliveryDetails = {
        deliveryAddress,
        orderNotes,
        email
      }

    const orderID =
      !getInStoreLoading && Number(inStoreData?.getInstoreOrders?.length) + 1;

    !getInStoreLoading &&
      dispatch(AddInstoreUSSDOrders({ carts, user, total, orderID, deliveryDetails }));

  };

  const handleGiftPayment = (carts, user, total) => {
    if (carts.length === 0) return;
    const deliveryAddress = `${address_line1}${address_line2 ? ", " +address_line2 : ""}, ${state}, ${country}`;
      const deliveryDetails = {
        deliveryAddress,
        orderNotes,
        email
      }

    const orderID =
      !getInStoreLoading && Number(inStoreData?.getInstoreOrders?.length) + 1;

    !getInStoreLoading &&
      dispatch(AddInstoreUSSDOrders({ carts, user, total, orderID, deliveryDetails }));

    setConfirmGiftCard(true);
  };

  const handleConfirmOrder = (carts, user, total) => {
    const checkMerchantId = carts.some((item) => {
      return item.merchant_id !== merchantDetails.id;
    });

    if (checkMerchantId) {
      toast.warning(
        "Cannot Check out item(s) from different merchant.",
        toastConfig
      );
      return;
    }

    setAskCart(false);
    setConfirmTill(false);
    // setShowInStoreOrders(true);
    handlePayInStore(carts, user, total);
    setOrderNotes("");
    setDeliveryDetails({
      email: "",
      deliveryAddress: "",
      orderNotes: ""
    })
    // setAddress_line1("");
    // setAddress_line2("");
    // setPostal_code("");
  };

  const radioOptions =  [
    { label: "Pickup", value: "Pickup" },
    { label: "Delivery", value: "Delivery"},
  ] 

  const handleRadioChange = (e) => {
    const { value } = e.target;
    if(value === "Delivery"){
      setPaywithDelivery(true);
      setDeliveryMethod("Delivery");
      customerLongAndLat.latitude 
      && customerLongAndLat.longitude 
      && getMerchantLatLong(customerLongAndLat.latitude, customerLongAndLat.longitude)
    }else if(value === "Pickup"){
      const isPayStackApp = applications && applications.filter((app) => {
        return app.app.app_name === "Paystack Terminal";
      });

      isPayStackApp && isPayStackApp.length > 0 
        ? setIsPayStack(true) 
          : setIsPayStack(false);
      setPaywithDelivery(false);
      setDeliveryMethod("Pickup");
      setDeliveryPrice(null);
      setWeight(null);
    }
  }
  
  const handlePayInStore = (carts, user, total) => {
    if (!user) return;

    // dispatch(AddInstoreOrders( {carts, user, total} ));
    addOrders({ variables: { data: orderdetails } });
    // paywithDelivery && merchantDetails.currency === "NGN" && sendDeliveryRequest();

    if (error) {
      toast.error(`Unable to process order, try again`, toastConfig);
    } else {
      toast.success("Items Sent to Store", toastConfig);

      setShowInStoreOrders(true);
    }
  };

  const history = useHistory();
  useEffect(() => {
    const isMerchantUrl = localStorage.getItem("urewards_merchant_url");
    if(isMerchantUrl){
      setMerchantUrl(JSON.parse(isMerchantUrl));
    }else{
      message.error("merchant not found");
      history.push("/404");
    }

    return () => {}
  }, []);

  // useEffect(() => {
  //   if (!user) {
  //     merchantUrl ? history.push(merchantUrl) : history.push("/404");
  //   }
  // }, [user, merchantUrl, history]);

  const handleLinkClick = () => {
    dispatch(clearCart());

    merchantUrl ? history.push(merchantUrl) : history.push("/404");
  };

  const handleStripePayment = () => {
    handleConfirmOrder(carts, user, totalOrder(carts));
  }

  const handlePaymentWithGiftcard = (total) => {
    if (giftcard === null)
      return message.warn("No Card to Pay from, pls select Card");

    if (Number(giftcard?.amount) < Number(total))
      return message.error("cannot continue payment due to insufficient fund");

    if (spendData !== null && spendLoading === false) {
      const top = {
        amount: parseFloat(total),
        card_number: giftcard?.card_number,
        merchant_name: merchantDetails?.business_name,
        cartItems: carts,
      };
      spendRefetch({ data: top });
    }
    spendGiftCard({
      variables: {
        data: {
          amount: total,
          card_number: giftcard?.card_number,
          merchant_name: merchantDetails?.business_name,
          cartItems: carts,
        },
      },
      onCompleted: (data) => {
        // message.success("success");
        setConfirmGiftCard(false);
        setShowInStoreOrders(true);
        setOrderNotes("");
        addOrders({ 
          variables: { data: orderdetails },
          onCompleted: () => {
            handlePostSales(orderdetails, "card");
          }
         });
        paywithDelivery && merchantDetails.currency === "NGN" && sendDeliveryRequest();
        setDeliveryDetails({
          email: "",
          deliveryAddress: "",
          orderNotes: ""
        })
        // setAddress_line1("");
        // setAddress_line2("");
        // setPostal_code("");
        // dispatch( clearGiftCard() );
      },
      onError: (error) => {
        message.error(error.message);
      },
    });
  };

  const payAfterStrip = () => {
    const { search } = location;
    if(search !== ""){
      const searchQuery = search.split("=")[1];
      if (searchQuery === "successful") {
        message.success("Payment confirmed");
        setStripePaymentSuccess(true);
        handleStripePayment();
        handlePostSales(orderdetails, "card");
      } else if (searchQuery === "cancelled") {
        message.error("Unable to process your stripe payment, please try again");
        setStripePaymentSuccess(false);
      }
    }
  };

  

  return (
    <>
      <Helmet>
      <meta
          name="description"
          content={
            merchant?.description ||
            `Browse products and Services from ${merchant?.business_name || `loystar shop`}, make a purchase and earn rewards 🎁`
          }
        />
        <link rel="shortcut icon" id="favicon" href={merchant?.cover_image || LogoBlack} />
        <title>{merchant?.business_name || "Loystar Shop Cart"}</title>
        <link rel="icon" id="icon-fav" href={merchant?.cover_image || LogoBlack} />
      </Helmet>
      {openBankModal && <USSDModal
        open={openBankModal}
        onCancel={() => setOpenBankModal(false)}
        total={totalOrder(carts).toFixed(2)}
      />}
      {successUSSD && <SuccessModal
        open={successUSSD}
        // open={true}
        onCancel={() => setSuccessUSSD(false)}
        body={`Successfully paid ${
          merchantDetails?.currency + totalOrder(carts).toFixed(2)
        } via USSD!`}
      />}
      {showInStoreOrders && <OrderSuccess
        open={showInStoreOrders}
        // open={true}
        onCancel={() => {
          dispatch(clearCart());
          setShowInStoreOrders(false);
          setAskCart(false);
          const user = JSON.parse(localStorage.getItem("user_customer_details"));
          const newUser = {...user, email:email, postcode: postal_code, state: state, country: country, address_line1: address_line1, address_line2: address_line2}
          localStorage.setItem("user_customer_details", JSON.stringify(newUser))
        }}
        user={user}
        orderdetails={orderdetails}
        merchantDetails={merchantDetails}
        carts={carts}
        onClick={() => handleLinkClick()}
        totalCustomerPoints={totalCustomerPoints}
        word1="WE'RE PREPARING YOUR ORDER"
        merchant={merchant}
        isbranchSet={isbranchSet}
        branch_address1={branch_address1}
        branch_address2={branch_address2}
        deliveryPrice={deliveryPrice}
        serviceAmount={serviceAmount}
        custAddress={deliveryDetails.deliveryAddress}
      />}
      {ussdOrderSuccess && <OrderSuccess
        open={ussdOrderSuccess}
        // open={true}
        onCancel={() => {
          setOpenBankModal(false);
          setAskCart(false);
          dispatch(clearCart());
          dispatch(clearUSSDState());
        }}
        user={user}
        orderdetails={orderdetails}
        merchantDetails={merchantDetails}
        carts={carts}
        onClick={() => handleLinkClick()}
        totalCustomerPoints={totalCustomerPoints}
        word1="WE'RE PREPARING YOUR ORDER"
        merchant={merchant}
        isbranchSet={isbranchSet}
        branch_address1={branch_address1}
        branch_address2={branch_address2}
        deliveryPrice={deliveryPrice}
        serviceAmount={serviceAmount}
        custAddress={deliveryDetails.deliveryAddress}
      />}
      {confirmGiftCard && <ConfirmModal
        open={confirmGiftCard}
        onCancel={() => setConfirmGiftCard(false)}
        onOk={() => handlePaymentWithGiftcard(totalOrder(carts))}
        body="Tap ok to send pay with Gift Card"
        loading={spendLoading}
      />}
      
      {confirmTill && <ConfirmModal
        open={confirmTill}
        onCancel={() => setConfirmTill(false)}
        onOk={() =>{
           handleConfirmOrder(carts, user, totalOrder(carts))
        }}
        body={
          <>
            <p>Tap ok to send your order and pickup at: {`${branch_address1 ? branch_address1 : merchantDetails?.address_line1}, ${branch_address2 ? branch_address2 : merchantDetails?.address_line2} ${branch_address1 ? "" : merchantDetails?.state}`}</p>
          </>
        }
      />}
      {confirmUSSD && <ConfirmModal
        open={confirmUSSD}
        onCancel={() => setConfirmUSSD(false)}
        onOk={() => {
          setOpenBankModal(true);
        }}
        body="Tap ok to pay with USSD"
      />}
      {confirmClear && <ConfirmModal
        open={confirmClear}
        onCancel={() => setConfirmClear(false)}
        onOk={() => {
          dispatch(clearCart());
          message.success("cart is cleared");
          setConfirmClear(false);
        }}
        body="Do you want to clear the cart?"
      />}
      {askCart && <AskPayment
        open={askCart}
        onCancel={() => setAskCart(false)}
        getInStoreLoading={getInStoreLoading}
        merchant={merchant}
        merchantDetails={merchantDetails}
        carts={carts}
        deliveryPrice={deliveryPrice}
        serviceAmount={serviceAmount}
        sendDeliveryRequest={sendDeliveryRequest}
        paywithDelivery={paywithDelivery}
        setDeliveryDetails={setDeliveryDetails}
        isStripeEnabled={isStripeEnabled}
        isCredPalEnabled={isCredPalEnabled}
        isPayStack={isPayStack}
        onClickTill={() => {
          !getInStoreLoading &&
            handleAreYouSure(carts, user, totalOrder(carts));
          setConfirmTill(true);
        }}
        onClickUSSD={() => {
          handleUSSDPayment(carts, user, totalOrder(carts));
        }}
        onClickGiftCard={() => {
          handleGiftPayment(carts, user, totalOrder(carts));
        }}
        handleStripePayment={handleStripePayment}
        handlePayStackPayment={handlePayStackPayment}
        setShowInStoreOrders={setShowInStoreOrders}
        setOrderDetails={()=>handleAreYouSureForStrip(carts, user, totalOrder(carts))}
        loading={loading}
        handlePostSales={handlePostSales}
      />}
      {openSelectGooglelocation && (
        <GoogleMapComponent 
          open={openSelectGooglelocation}
          onCancel={() => setOpenSelectGoogleLocation(false)}
          setCustomerLongAndLat={setCustomerLongAndLat}
          setAddress_line1={setAddress_line1}
          setAddress_line2={setAddress_line2}
          setState={setState}
          setCountry={setCountry}
          setPostal_code={setPostal_code}
          getMerchantLatLong={() => {
            if(customerLongAndLat.latitude && customerLongAndLat.longitude) {
              setOpenSelectGoogleLocation(false);
              handleUpdateDetails();
              getMerchantLatLong(customerLongAndLat.latitude, customerLongAndLat.longitude);
            }else{
              message.error("Ensure your full address is captured and include landmarks in the note to aid prompt delivery.")
            }
            
          }}
        />
      )}

      
      <Header
        titleComponent={
          <h2 className="title_text" style={{ margin: 0 }}>
            Cart
          </h2>
        }
        merchantee
        // merchantLink={merchantUrl}
      />
      
      <div className="container_main">
        <div
          className="flex_center_column"
          style={{
            backgroundImage: `url(${
              merchant?.cover_image || JayAutos
            })`,
            width: "100%",
            height: "400px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundColor: "rgba(0,0,0,0.4)",
            backgroundBlendMode: "multiply",
            backgroundPosition: "center"
          }}
        >
          <div
            style={{
              textAlign: "center",
              margin: "0 1.5rem 0",
            }}
          >
            <h3 className="title_text" style={{ marginBottom: 0 }}>
              {merchant?.business_name}
            </h3>
            {/* <p style={{ marginTop: 10, fontSize: "14x", lineHeight: "150%" }}>
              {merchant?.business_type}
            </p> */}
            <p style={{ marginTop: 10, fontSize: "14px", lineHeight: "150%" }}>
              {merchant?.description}
            </p>
            {branch_address1 ? <p>{branch_address1 ?  branch_address1 : "" }{branch_address2 ? ", " + branch_address2 : "" }</p> : <p style={{ marginTop: 10, fontSize: "14px", lineHeight: "150%" }}>
              {merchant?.address_line1}
              {merchant?.address_line2 ? ", " + merchant?.address_line2 : ""}
              {merchant?.state ? ", " + merchant?.state : ""}
              {merchant?.country ? " ," + merchant?.country : ""}
            </p>}
          </div>
        </div>
        <div>
          <h3
            style={{
              fontWeight: 600,
              fontSize: "16px",
              marginTop: 20,
              paddingBottom: 15,
              borderBottom: "1px solid #fff",
            }}
          >
            {carts.length === 0 ? "No Pending Payment" : "Item(s) in your cart. Ready to checkout?"}
          </h3>

          
            {carts && carts.length === 0 ? (
              <h2>Your Cart is Empty</h2>
            ) : (
              <Row>
                {carts.map((item, index) => {
                return (
                    <Col
                      key={index}
                      // span={}
                      lg={12}
                      md={12}
                      sm={24}
                      xs={24}
                      style={{
                        margin: "10px 0 -1px",
                        padding: "15px 5px 15px 0",
                        borderBottom: "1px solid #fff",
                      }}
                    >
                      <Row key={index}>
                        <Col
                          // span={6}
                          sm={6}
                          xs={6}
                          lg={6}
                          md={6}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            // marginRight: "8px",
                          }}
                        >
                          <img
                            src={
                              item.variants.length > 0 && item.variants[0].picture 
                              ? item.variants[0].picture 
                              : item.picture ? item.picture : CartItem1}
                            alt="cart item"
                            style={{
                              width: "60px",
                              height: "60px",
                              borderRadius: "50%",
                              filter: "contrast(1.75)",
                              backgroundSize: "cover",
                            }}
                          />
                        </Col>
                        <Col
                          // span={10}
                          sm={10}
                          lg={12}
                          md={12}
                          xs={12}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            gap: "-1rem",
                            // marginRight: "20px"
                          }}
                        >
                          <Row>
                            <p
                              style={{
                                fontWeight: 600,
                                fontSize: "14px",
                                margin: 0,
                                lineHeight: "17px",
                              }}
                              key={index}
                            >
                              {item.service_name === null ? item.name: item.service_name}<br/>
                              {item.variants && item.variants.length !== 0 && item.variants[0].value }
                              {item.custom_quantities && item.custom_quantities.length !== 0 && item.custom_quantities[0].name }
                              {item.products 
                              && item.products.length > 0 
                              && item.products.map((prod,index)=>{
                                return <span style={{display: "inline-block"}}><b>{prod.name}</b>-{item.bundle_quantity[index]} (units)</span>
                              }) }
                            </p>
                          </Row>
                          {/* <Row>
										<p style={{ fontWeight: 400, fontSize: "10px", margin: 0 }} key={index}>
										{item.description}{" "}
										</p>
									</Row> */}
                          {/* <Row>
										<p
											style={{ color: "#6B6A6A", margin: 0, paddingRight: 20 }} key={index}>
											{item ? item.description : null}
										</p>
									</Row> */}
                          <Row>
                            <p
                              style={{
                                color: "#6B6A6A",
                                margin: 0,
                                paddingRight: 20,
                                fontSize: "11px",
                              }}
                              key={index}
                            >
                              {item.variants && item.variants.length > 0 ? Number(item.variants[0].price).toFixed(2) : item.custom_quantities && item.custom_quantities.length > 0 ?  Number(item.custom_quantities[0].price).toFixed(2) : Number(item.price).toFixed(2)} x {item.quantity}
                            </p>
                          </Row>
                          {/* <Row>
										<p
											style={{ 
												color: "#6B6A6A", 
												margin: 0, 
												paddingRight: 20,
												fontWeight: 600, 
												fontSize: "16px" 
												}} 
												key={index}
											>
											Total: {merchantDetails && merchantDetails.currency || merchant?.currency+ " " + calculateTotal(item.quantity, item.price)}
										</p>
									</Row> */}
                        </Col>
                        <Col
                          // span={6}
                          lg={4}
                          sm={6}
                          xs={4}
                          md={4}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "-1rem",
                            marginRight: "20px",
                          }}
                        >
                          <Row>
                            <div>
                              <span
                                style={{ color: "#fff", fontSize: "16px" }}
                                onClick={() =>
                                  reduceQuantity(
                                    item,
                                    item.quantity,
                                    item.total_quantity
                                  )
                                }
                              >
                                <p className="cart_icons">-</p>
                              </span>
                              <span
                                style={{
                                  margin: "0 10px",
                                  color: "#fff",
                                  fontSize: "16px",
                                }}
                                key={index}
                              >
                                {item.quantity}
                              </span>
                              <span
                                style={{ color: "#fff", fontSize: "16px" }}
                                onClick={() =>
                                  addQuantity(
                                    item,
                                    item.quantity,
                                    item.total_quantity
                                  )
                                }
                              >
                                <p className="cart_icons">+</p>
                              </span>
                            </div>
                          </Row>
                          <Row>
                            <span
                              style={{
                                fontSize: "16px",
                                color: "#fff",
                              }}
                            >
                              {item.variants && item.variants.length > 0 ? (
                                Number(item.variants[0].price) * Number(item.quantity)
                              ).toFixed(2) :  item.custom_quantities && item.custom_quantities.length > 0 ? (
                                Number(item.custom_quantities[0].price) * Number(item.quantity)
                              ).toFixed(2) : (
                                Number(item.price) * Number(item.quantity)
                            ).toFixed(2)}
                            </span>
                          </Row>
                        </Col>
                        {/* <span
									style={{ 
										color: "#fff",  
										margin: "5px auto 0",
										display: "flex",
										justifyContent: "center",
										alignItems: "center"
										}}
									onClick={()=>handleDeleteOder(item)}
									className="cart_icons"
								><AiFillDelete className="cart_icons"/></span> */}
                      </Row>
                    </Col>
                );
              })}
              </Row>
            )}
            {/* </div> */}
          

          {carts.length !== 0 && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{display: "flex", flexDirection: "column", gap:5}}>
                  <p style={{ fontWeight: 600, fontSize: "16px", margin:0 }}>
                    Total Amount:
                  </p>
                  {loading ? <Spin size="small" indicator={<LoadingOutlined style={{fontSize: 20, color:"#fff"}} spin  />} /> :deliveryPrice && <p style={{ fontWeight: 600, fontSize: "16px", margin:0 }}>
                    Delivery Fee:
                  </p>}
                  {/* <p style={{ fontWeight: 600, fontSize: "16px", margin:0 }}>
                    Service Charge:
                  </p> */}
                </div>
                
                <div style={{display: "flex", flexDirection: "column", gap:5}}>
                  <p style={{ fontWeight: 600, fontSize: "22px", margin:0 }}>
                    {merchantDetails && merchantDetails.currency}{" "}
                    {carts && totalOrder(carts).toFixed(2)}
                  </p>
                  {loading ? <Spin size="small" indicator={<LoadingOutlined style={{fontSize: 20}} spin  />} /> : deliveryPrice && <p style={{ fontWeight: 600, fontSize: "22px", margin:0 }}>
                    {merchantDetails && merchantDetails.currency}{" "}
                    {deliveryPrice ? deliveryPrice  : ""}
                  </p>}
                  {/* <p style={{ fontWeight: 600, fontSize: "16px", margin:0 }}>
                  {merchantDetails && merchantDetails.currency}{" "}
                  {carts && serviceAmount.toFixed(2)}
                  </p> */}
                </div>
                
              </div>
            </>
          )}
          {carts.length > 0 && 
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.3rem",
              marginBottom: paywithDelivery ? "1rem" : "0"
            }}
          >
            <p style={{margin: "0"}}>Select delivery method</p>
            <span>
              <Radio.Group
                value={deliveryMethod}
                options={radioOptions}
                onChange={(e)=>handleRadioChange(e)}
              />
            </span>
          </div>}
          
          {carts.length > 0 && paywithDelivery && <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "15px"
            }}
          >
            <Input 
              placeholder="Email address"
              value={email}
              name="email"
              size="large"
              onChange={(e)=>{
                  const { name, value } = e.target;
                  setEmail(value)
                  setDeliveryDetails((prev)=>{
                      return {
                          ...prev,
                          [name]: value
                      }
                  })
              }}
              type="email"
            />
          
            <div>
            <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.2rem"
                }}
              >
                <div style={{width: "50%"}}>
                  <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_KEY} libraries={['places']}>
                    <div>
                      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                        {/* <input
                          type="text"
                          placeholder="Search for a place"
                          style={{ width: '300px', height: '40px' }}
                          ref={inputRef}
                        /> */}
                        <Input 
                            // placeholder="address line1"
                            value={address_line1}
                            // name="address_line1"
                            size="large"
                            onChange={(e)=>setAddress_line1(e.target.value)}
                            type="text"
                            placeholder="Search for a place"
                            style={{ width: '100%'}}
                            ref={inputRef}
                        />
                      </Autocomplete>
                    </div>
                  </LoadScript>
                </div>
                
                {/* <Input 
                    placeholder="address line1"
                    value={address_line1}
                    name="address_line1"
                    size="large"
                    onChange={(e)=>setAddress_line1(e.target.value)}
                    type="text"
                /> */}
                <Input 
                  placeholder="address line2"
                  value={address_line2}
                  size="large"
                  name="address_line2"
                  style={{width: "50%"}}
                  onChange={(e)=>setAddress_line2(e.target.value)}
                  type="text"
                  onBlur={getUserLocation}
                />
              </div>
              {carts.length > 0 && paywithDelivery  && <div
                  style={{
                    // display: "flex",
                    // flexDirection: "row",
                    // alignItems: "center",
                    // gap: "2rem",
                    marginBottom: 0
                  }}
                >
                  <p style={{margin: "0", cursor: "pointer"}} onClick={() => getLocationCoordinate()}>Use current location</p> 
                  {addressByLocation && <span style={{color:"#fff", fontSize:12, margin:0, marginTop:-5}}>Ensure your full address is captured and include landmarks in the note to aid prompt delivery.</span>}
            </div>}
            </div>
            
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.2rem"
              }}
            >
              <CountryDropdown
                value={country}
                defaultOptionLabel="Select Country"
                onChange={(val) => setCountry(val)}
                className="ant-input-lg ant-input"
              />
              <RegionDropdown
                country={country}
                value={state}
                defaultOptionLabel="Select State/Region"
                onChange={(val) => setState(val)}
                className="ant-input-lg ant-input"
                />
              <Input 
                placeholder="postal code"
                value={postal_code}
                name="postal_code"
                size="large"
                onChange={(e)=>setPostal_code(e.target.value)}
                type="text"
              />
            </div>

            {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.2rem"
              }}
            >
              <InputNumber 
                placeholder="Weight"
                value={weight}
                name="weight"
                size="large"
                onChange={(e)=>setWeight(e.target.value)}
                style={{width: "50%"}}
              />
            </div> */}
            
          </div>}
          {carts.length !== 0 && <div style={{
            marginTop: "1rem"
          }}>
            <TextArea 
              placeholder="Order Notes"
              value={orderNotes}
              name="order notes"
              maxLength={100}
              rows={5}
              onChange={(e)=>{
                setOrderNotes(e.target.value)
                setDeliveryDetails((prev)=>{
                  return {
                    ...prev,
                    orderNotes: e.target.value
                  }
                })
              }}
            />
          </div>}
          {/* {paywithDelivery && !addressByLocation && <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 10
              }}
            >
              <Button 
                style={{
                  height:45,
                  fontWeight:"500"
                }}
              onClick={() => {
                if(
                  email === "" || 
                  address_line1 === "" || 
                  address_line2 == "" || 
                  state ===  "" || 
                  country === "" || postal_code === ""
                ){
                  setShouldUpdateDetails(true);
                  return message.info("Please fill all fields")
                }
                getUserLocation()
              }}>Update Address</Button>
            </div>} */}
          <div
            className="flex_center"
            style={{
              borderBottom: "1px solid #fff",
              paddingTop: 60,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "0.5rem",
              // position: "fixed",
              // bottom: "5rem"
            }}
          >
            <Button
              type="primary"
              className="cart_button"
              onClick={() => {
                refetch();
                if( paywithDelivery){
                  if(
                    email === "" || 
                    address_line1 === "" || 
                    address_line2 == "" || 
                    state ===  "" || 
                    country === "" 
                  ){
                    setShouldUpdateDetails(true);
                    return message.info("Please fill all fields")
                  }

                  shouldUpdateDetails && setDeliveryDetails({
                    email: email,
                    deliveryAddress: `${address_line1}, ${address_line2}, ${state}, ${country}`
                  });
                  handleUpdateDetails();
                  if(merchantDetails.currency === "NGN" && !getInStoreLoading){
                    if(paywithDelivery) handlePayStackPayment(carts, user, totalOrder(carts));
                    else handleAreYouSure(carts, user, totalOrder(carts));
                  }
                  if(!weight){
                    message.warn("Select item weight to checkout or choose pickup method!")
                    return setTimeout(() => {
                      handleShowWeight();
                    }, 1000);
                  }
                }
                // paywithDelivery && 
                setAskCart(true);
              }}
              disabled={carts.length === 0 ? true : false}
              style={{
                display: carts.length === 0 ? "none" : "block",
                width: "277px",
              }}
            >
              Checkout
            </Button>
            <Link to={merchantUrl ? merchantUrl : "/404"} className="cart_action">
              Add More Items
            </Link>
            <p
              onClick={() => {
                setConfirmClear(true);
              }}
              className="cart_action"
              style={{ display: carts.length === 0 ? "none" : "block" }}
            >
              Cancel
            </p>
          </div>
        </div>

        {/* <div>
					<h3
						style={{
							fontWeight: 600,
							fontSize: "28px",
							marginTop: 20,
							paddingBottom: 30,
						}}>
						Saved Items
					</h3>

					<Row
						style={{
							borderBottom: "1px solid #fff",
						}}>
						<Col
							md={12}
							sm={24}
							style={{
								margin: "10px 0 -1px",
								padding: "20px 0",
								borderBottom: "1px solid #fff",
							}}>
							<Row>
								<Col span={5} style={{ display: "flex", alignItems: "center" }}>
									<img src={CartItem3} alt="cart item" />
								</Col>
								<Col span={14}>
									<Row>
										<p style={{ fontWeight: 600, fontSize: "14px", margin: 0 }}>
											Spark Plugs
										</p>
									</Row>
									<Row>
										<p style={{ fontWeight: 400, fontSize: "10px", margin: 0 }}>
											Jay Autos{" "}
										</p>
									</Row>
									<Row>
										<p
											style={{ color: "#6B6A6A", margin: 0, paddingRight: 20 }}>
											Lorem ipsum dolor sit amet, consectetur adipiscing
											elit.sed do eiusmod tempor
										</p>
									</Row>
								</Col>
								<Col span={5} style={{ display: "flex", alignItems: "center" }}>
									<Button style={{ background: "#6B6A6A" }}>Get</Button>
								</Col>
							</Row>
						</Col>

						<Col
							md={12}
							sm={24}
							style={{
								margin: "10px 0 -1px",
								padding: "20px 0",
								borderBottom: "1px solid #fff",
							}}>
							<Row>
								<Col span={5} style={{ display: "flex", alignItems: "center" }}>
									<img src={CartItem1} alt="cart item" />
								</Col>
								<Col span={14}>
									<Row>
										<p style={{ fontWeight: 600, fontSize: "14px", margin: 0 }}>
											14 In. Wrench
										</p>
									</Row>
									<Row>
										<p style={{ fontWeight: 400, fontSize: "10px", margin: 0 }}>
											Jay Autos{" "}
										</p>
									</Row>
									<Row>
										<p
											style={{ color: "#6B6A6A", margin: 0, paddingRight: 20 }}>
											Lorem ipsum dolor sit amet, consectetur adipiscing
											elit.sed do eiusmod tempor
										</p>
									</Row>
								</Col>
								<Col span={5} style={{ display: "flex", alignItems: "center" }}>
									<Button style={{ background: "#6B6A6A" }}>Get</Button>
								</Col>
							</Row>
						</Col>

						<Col
							md={12}
							sm={24}
							style={{
								margin: "10px 0 -1px",
								padding: "20px 0",
								borderBottom: "1px solid #fff",
							}}>
							<Row>
								<Col span={5} style={{ display: "flex", alignItems: "center" }}>
									<img src={CartItem1} alt="cart item" />
								</Col>
								<Col span={14}>
									<Row>
										<p style={{ fontWeight: 600, fontSize: "14px", margin: 0 }}>
											Spark Plugs
										</p>
									</Row>
									<Row>
										<p style={{ fontWeight: 400, fontSize: "10px", margin: 0 }}>
											Jay Autos{" "}
										</p>
									</Row>
									<Row>
										<p
											style={{ color: "#6B6A6A", margin: 0, paddingRight: 20 }}>
											Lorem ipsum dolor sit amet, consectetur adipiscing
											elit.sed
										</p>
									</Row>
								</Col>
								<Col span={5} style={{ display: "flex", alignItems: "center" }}>
									<Button style={{ background: "#6B6A6A" }}>Get</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</div> */}
      </div>
      <Footer active="cart" disable />
      <ToastContainer />
    </>
  );
}

const mapStateToProps = (state) => ({
  state: state,
  carts: state.carts,
  merchantDetails: state.merchantDetails,
  orderdetails: state.orderdetails,
  urewardsAuth: state.urewardsAuth,
  merchantState: state.merchantState,
  branch: state.branch
});
export default connect(mapStateToProps)(Cart);
